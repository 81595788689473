import React from "react"
import { Box, Container } from "@mui/material"
import { SONGS } from "../utils/types"

import SongCard from "./Card"
import acoustic from "../images/acoustic.jpg"
// import deadlymeadly from "../images/deadly-meadly.jpg"
// import animals from "../images/animal.jpg"
// import percussion from "../images/song-percussion.jpg"
// import pop from "../images/song-pop.jpg"
// import blinka from "../images/blinka.png"
// import car from "../images/car.png"
// import farm from "../images/farm.png"
// import christmas from "../images/christmas.png"
// import water from "../images/water.png"
// import human from "../images/human.png"
// import trollmor from "../images/trollmor.png"
// import church from "../images/church.png"
import boom from "../images/boombox.png"
import ship from "../images/ship.png"
import moon from "../images/moon.png"
import robot from "../images/robot.png"
import funk from "../images/funk.png"
import { useIsSmallScreen } from "../hooks/useIsSmallScreen"

export function handleSelectedSong(song: SONGS) {
  // Set selected song to be used throught the app
  localStorage.setItem("selectedSong", song)
}

const ChooseSong = ({ onSongSelect }: any) => {
  const isSmallScreen = useIsSmallScreen()
  const selectedSong = localStorage.getItem("selectedSong")

  const handleCardClick = (song: SONGS) => {
    handleSelectedSong(song)
    onSongSelect(song) // Notify the parent component of the selected song
  }

  return (
    <Container
      sx={{
        paddingTop: 5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <h3>Välj låt</h3>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: isSmallScreen ? "1fr" : "1fr 1fr 1fr",
          flexDirection: isSmallScreen ? "column" : "row",
          justifyContent: "center",
        }}
      >
        <SongCard
          title="Robot"
          description="David and Kristine"
          img={robot}
          song={SONGS.ROBOT}
          isSelected={selectedSong === SONGS.ROBOT}
          setSelectedCard={handleCardClick}
        />
		 <SongCard
          title="JPBeatBox"
          description="Jan Peter"
          img={boom}
          song={SONGS.JPBEATBOX}
          isSelected={selectedSong === SONGS.JPBEATBOX}
          setSelectedCard={handleCardClick}
        />
		<SongCard
          title="Klarmoon"
          description="Klara and Hamoon"
          img={moon}
          song={SONGS.KLARMOON}
          isSelected={selectedSong === SONGS.KLARMOON}
          setSelectedCard={handleCardClick}
        />
		<SongCard
          title="FunkiFunk"
          description="Tobias Kalliokulju"
          img={funk}
          song={SONGS.FUNKIFUNKI}
          isSelected={selectedSong === SONGS.FUNKIFUNKI}
          setSelectedCard={handleCardClick}
        />
		<SongCard
          title="Funki Ship"
          description="JANIS"
          img={ship}
          song={SONGS.FUNKISHIP}
          isSelected={selectedSong === SONGS.FUNKISHIP}
          setSelectedCard={handleCardClick}
        />
      </Box>
    </Container>
  )
}

export default ChooseSong
