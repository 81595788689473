export const MQTT_API = {
  CREDITS: {
    //TODO: change to unique and set up on HiveMQ
    USERNAME: "funkiApp1",
    PASSWORD: "funkiMQTT123",
  },

  INSTRUMENTS: {
    /**
     * These are the newly developed instruments that later will be used in pilottesting in Stockholm
     * These are 'connected' to app.funki.se
     */
    BUTTONS: "/buttons1",
    JOYSTICKS: "/joysticks1",
	JOYSTICKS2: "/joysticks2",
    LEVERS: "/levers1",
    TOUCH: "/touch1",
    ALL: "/all1",

    /**
     * These below is used in Kulturskolan pilottesting.
     * These are 'connected' to kulturskolan.funki.se
     */
    // BUTTONS: "/buttons",
    // JOYSTICKS: "/joysticks",
    // LEVERS: "/levers",
    // TOUCH: "/touch",
    //ALL: "/all"
  },

  TOPIC: {
    HEARTBEAT: "/heartbeat1",
    ON_START: "/onstart1",
    // HEARTBEAT: "/heartbeat",
    // ON_START: "/onstart",
    SONG: "/changeSong",
    VOLUME: "/changeVolume",
    BACKINGTRACK: "/backingtrack",
    METRONOME: "/metronome",
    SECTION: "/changeSection",
    PLAY_PAUSE: "/playPause",
  },
}

export const ROUTES = {
  HOME: "/",
  LIBRARY: "/library",
  ABOUT: "/about",
}
