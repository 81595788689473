import { Client } from "paho-mqtt"
import { MQTT_API } from "../utils/api"

function generateRandomClientId() {
  const randomNumber = Math.floor(Math.random() * 90000) + 10000
  return randomNumber.toString()
}

let mqttClient: Client | null = null
let isConnected: boolean = false

let connectedInstrumentCallback: any = null
let disconnectedInstrument: { id: string; timeStamp: number }[] = []
let disconnectedInstrumentCallback: any = null

const timeoutDuration = 10000

if (!mqttClient) {
  const options = {
    host: "c745b068f8a54074bd1f61681a38d9e1.s1.eu.hivemq.cloud",
    port: 8884,
    protocol: "wss",
    clientId: "web" + generateRandomClientId(),
    userName: MQTT_API.CREDITS.USERNAME,
    password: MQTT_API.CREDITS.PASSWORD,
    keepAliveInterval: 30,
  }

  mqttClient = new Client(options.host, options.port, options.clientId)

  const connectToMQTT = () => {
    mqttClient?.connect({
      useSSL: true,
      onSuccess: () => {
        console.log("Connected")

        isConnected = true

        handleSubscriptions()
      },
      onFailure: (error) => {
        console.log("Connection failed:", error.errorMessage)

        // Retry connection after a delay
        setTimeout(connectToMQTT, 5000) // Retry after 5 seconds
      },
      userName: options.userName,
      password: options.password,
      keepAliveInterval: options.keepAliveInterval,
    })
  }

  // Initial connection
  connectToMQTT()

  mqttClient.onMessageArrived = (message) => {
    console.log(
      `Received message on topic '${message.destinationName}': ${message.payloadString}`
    )

    if (
      message.destinationName === MQTT_API.TOPIC.ON_START ||
      message.destinationName.startsWith(MQTT_API.TOPIC.ON_START + "/")
    ) {
      if (connectedInstrumentCallback) {
        connectedInstrumentCallback(message.payloadString)
      }
    }

    if (message.destinationName === MQTT_API.TOPIC.HEARTBEAT) {
      connectedInstrumentCallback(message.payloadString)
      const existingIndex = disconnectedInstrument.findIndex(
        (instrument) => instrument.id === message.payloadString
      )

      if (existingIndex === -1) {
        // The key doesn't exist in disconnectedInstrument, so add it.
        disconnectedInstrument.push({
          id: message.payloadString,
          timeStamp: Date.now(),
        })
      } else {
        // The key already exists, so update the timeStamp.
        disconnectedInstrument[existingIndex].timeStamp = Date.now()
      }
    }
  }

  mqttClient.onConnectionLost = (error) => {
    console.error(`Connection lost: ${error.errorMessage}`)
    isConnected = false
    connectToMQTT()
  }
}

// Check for disconnected instrument
setInterval(() => {
  const currentTime = Date.now()

  if (disconnectedInstrument) {
    const timeoutInstruments = disconnectedInstrument.filter((instrument) => {
      return currentTime - instrument.timeStamp > timeoutDuration
    })

    if (timeoutInstruments.length > 0) {
      timeoutInstruments.forEach((timeoutInstrument) => {
        disconnectedInstrumentCallback(timeoutInstrument.id)
        disconnectedInstrument?.filter(
          (instrument) => instrument.id !== timeoutInstrument.id
        )
      })
    }
  }
}, 5000)

export const setMessageCallback = (
  connectedInstrument: any,
  disconnectedInstrument: any
) => {
  connectedInstrumentCallback = connectedInstrument
  disconnectedInstrumentCallback = disconnectedInstrument
}

function handleSubscriptions() {
  mqttClient?.subscribe(MQTT_API.TOPIC.ON_START + "/#")
  mqttClient?.subscribe(MQTT_API.TOPIC.HEARTBEAT + "/#")
}

export { mqttClient, isConnected }
