// Welcome.js
import React from "react"
import Instruments from "../components/Instruments"
import { Container } from "@mui/material"
import Section from "../components/Section"
import { SONGS } from "../utils/types"

const Welcome = ({ data: users }: any) => {
  // Fetch selected song from local storage
  const selectedSong = localStorage.getItem("selectedSong")

  const showSections = true
    // selectedSong === SONGS.ACOUSTIC || selectedSong === SONGS.DEADLYMEADLY

  return (
    <Container>
      <Instruments data={users} />
      {showSections && <Section />}
    </Container>
  )
}

export default Welcome
