import React from "react"
import buttons from "../images/Instrument-Buttons.png"
import levers from "../images/Instrument-Levers.png"
import joysticks from "../images/Instrument-Joysticks.png"
import touch from "../images/Instrument-Touch.png"

import { Box, Container, Slider } from "@mui/material"
import { MQTT_API } from "../utils/api"

import { useIsSmallScreen } from "../hooks/useIsSmallScreen"
import { handleChangeVolume } from "../mqtt/appToInstrument"

// Returnerar korrekt bild beroende på vilket instrument id vi anropar med
function handleImageSource(id: any): any {
  if (id === MQTT_API.INSTRUMENTS.BUTTONS) {
    return buttons
  } else if (
    id === MQTT_API.INSTRUMENTS.JOYSTICKS || id ===
    MQTT_API.INSTRUMENTS.JOYSTICKS2
  ) {
    return joysticks
  } else if (id === MQTT_API.INSTRUMENTS.LEVERS) {
    return levers
  } else if (id === MQTT_API.INSTRUMENTS.TOUCH) {
    return touch
  } else {
    return buttons
  }
}

const Instruments = ({ data }: any) => {
  const isSmallScreen = useIsSmallScreen()

  const handleSlider = (
    event: React.ChangeEvent<HTMLInputElement>,
    instrument: any
  ) => {
    handleChangeVolume(event.target.value.toString(), instrument)
  }

  return (
    <Container
      sx={{
        paddingTop: 5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "50vh",
      }}
    >
      {data?.length ? (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: isSmallScreen ? "1fr" : "1fr 1fr", // Adjust the number of columns as needed
            gap: "1rem", // Adjust the gap between items
          }}
        >
          {data?.map((instrument: any, idx: number) => (
            <Box key={idx} sx={{ display: "flex" }}>
              <Slider
                sx={{
                  '& input[type="range"]': {
                    WebkitAppearance: "slider-vertical",
                  },
                }}
                aria-label="Slider"
                orientation="vertical"
                // getAriaValueText={valuetext}
                valueLabelDisplay="auto"
                defaultValue={1}
                min={0}
                max={3}
                step={0.2}
                onChange={(event: any) => handleSlider(event, instrument)}
              />

              <Box>
                <img
                  src={handleImageSource(instrument)}
                  alt="Bild på instrument"
                  width="80%"
                />
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        <Box>
          <h4>Väntar på Funki instrument...</h4>
        </Box>
      )}
    </Container>
  )
}

export default Instruments
